<template>
  <!-- index.vue -->
  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    :class="{
      'app-admin-wrap-layout-1 sidebar-mini': getThemeMode.verticalSidebarMini,
      'sidebar-close': !getThemeMode.verticalSidebarDrawer,
    }"
  >
    <app-bar />
    <side-bar />
    <base-view />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    BaseView: () => import('./View'),
    SideBar: () => import('./Sidebar'),
    AppBar: () => import('./AppBar'),
  },

  computed: {
    ...mapGetters(['getThemeMode']),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
